var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.unitOpenerData
    ? _c(
        "div",
        {
          staticClass: "unit-opener-overview",
          style: { backgroundImage: "url(" + _vm.background + ")" }
        },
        [
          [
            _c(
              "div",
              {
                staticClass: "topheader-unit-titlewrapper",
                style: { background: _vm.unitOpenerData.titleBackgroundColor }
              },
              [
                _c("div", { staticClass: "topheader-unit-title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "unit-information flex-container",
                      on: { click: _vm.showTeacherPage }
                    },
                    [
                      _vm.infoIcon
                        ? _c("img", { attrs: { src: _vm.infoIcon } })
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "span",
                    {
                      class: _vm.isHotSpot
                        ? "overview-title cursor-pointer pl-2 heading-text-shadow"
                        : "overview-title cursor-pointer pl-2",
                      style: { color: _vm.unitOpenerData.color },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.showUnitDescription($event)
                        },
                        mouseover: _vm.showUnitDescription
                      }
                    },
                    [_vm._v(_vm._s(_vm.unitOpenerData.title))]
                  )
                ])
              ]
            ),
            _c("div", { staticClass: "unit-overview-wrapper" }, [
              _c("div", { staticClass: "content-wrapper" }, [
                _c("div", { staticClass: "unit-hotspot-description" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row justify-content-end",
                      staticStyle: { margin: "0px" }
                    },
                    [
                      _vm.hotspotTitle
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "closable",
                                  rawName: "v-closable",
                                  value: {
                                    exclude: [""],
                                    handler: "onCloseDescription"
                                  },
                                  expression:
                                    "{exclude: [''],handler: 'onCloseDescription'}"
                                }
                              ],
                              staticClass: "col-3 p-3 white-bg",
                              class: _vm.isHotSpot
                                ? "unit-description"
                                : "content"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "unit-description-content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row justify-content-between",
                                      staticStyle: { margin: "0px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "unit-description-heading"
                                        },
                                        [
                                          _c(
                                            "h4",
                                            {
                                              staticClass:
                                                "justify-content-end mt-1 mb-0 heading-font-wt"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.isHotSpot
                                                      ? "Unit Description"
                                                      : _vm.hotspotTitle
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "unit-description-audio"
                                            },
                                            [
                                              _c("AudioToggle", {
                                                key: _vm.audioId,
                                                attrs: {
                                                  "white-background": true,
                                                  audio: _vm.audioObj
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "unit-desc-text",
                                    staticStyle: { "font-family": "'Roboto'" },
                                    domProps: {
                                      innerHTML: _vm._s(_vm.hotspotDescription)
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "hotspot-row" },
                  _vm._l(_vm.hotspotComponent, function(hotspot, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "hotspot-img",
                        class: { active: _vm.activeCard === i }
                      },
                      [
                        hotspot.hotspotDescription != ""
                          ? _c("img", {
                              staticClass: "width-100 active-img",
                              attrs: {
                                src: _vm.getHotSpotImage(hotspot.imageName),
                                alt: ""
                              },
                              on: {
                                mouseover: function($event) {
                                  _vm.activeImage(i, hotspot)
                                },
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.activeImage(i, hotspot)
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  })
                )
              ]),
              _c("div", { staticClass: "image-wraper" }, [
                _c("div", { staticClass: "right-contentwrapper white-bg" }, [
                  _c(
                    "div",
                    {
                      staticClass: "right-image-wrapper right-image-wrapper-box"
                    },
                    [
                      _c("img", {
                        staticClass: "width-100 right-image-wrapper",
                        attrs: { src: _vm.storyImage }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "below-wrapper pb-2" }, [
                    _c("div", { staticClass: "bill-banner-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn round primary unit-opener-btn",
                          on: { click: _vm.gotToUnitStory }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("read")) +
                              "\n              "
                          )
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "p-2 text-center overflow-clip mb-4 custom-scrollbar"
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "heading-font-wt-bill mt-0 mb-2 pl-1"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.unitOpenerData.story.storyTitle) +
                                "\n              "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "bill-text-description" }, [
                          _c("span", {
                            staticClass: "text-left overflow-clip",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.unitOpenerData.story.storyDescription
                              )
                            }
                          })
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "lesson-btn-opener white-bg" }, [
                      _c("div", { staticClass: "bill-banner-btn " }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn round primary unit-opener-btn",
                            on: { click: _vm.goToLessonsDashboard }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("lessons")) +
                                "\n              "
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }