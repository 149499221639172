<template>
  <div
    class="unit-opener-overview"
    v-if="unitOpenerData"
    v-bind:style="{ backgroundImage: 'url(' + background + ')' }"
  >
    <template>
      <div
        class="topheader-unit-titlewrapper"
        :style="{ background: unitOpenerData.titleBackgroundColor }"
      >
        <div class="topheader-unit-title">
          <div class="unit-information flex-container" @click="showTeacherPage">
            <img v-if="infoIcon" :src="infoIcon" />
          </div>
          <span
            :style="{ color: unitOpenerData.color }"
            :class="
              isHotSpot
                ? 'overview-title cursor-pointer pl-2 heading-text-shadow'
                : 'overview-title cursor-pointer pl-2'
            "
            @click.stop="showUnitDescription"
            @mouseover="showUnitDescription"
            >{{ unitOpenerData.title }}</span
          >
          <!-- @mouseleave="onCloseDescription" -->
        </div>
      </div>
      <div class="unit-overview-wrapper">
        <div class="content-wrapper">
          <div class="unit-hotspot-description">
            <div class="row justify-content-end" style="margin: 0px"  >
              <div
                :class="isHotSpot ? 'unit-description' : 'content'"
                class="col-3 p-3 white-bg"
                v-if="hotspotTitle"
                v-closable="{exclude: [''],handler: 'onCloseDescription'}"
              >
              <div class="unit-description-content">
                <div class="row justify-content-between" style="margin: 0px">
                  <div class="unit-description-heading">
                    <h4 class="justify-content-end mt-1 mb-0 heading-font-wt">
                      {{ isHotSpot ? "Unit Description" : hotspotTitle }}
                    </h4>
                    <div class="unit-description-audio">
                      <AudioToggle
                        :key="audioId"
                        :white-background="true"
                        :audio="audioObj"
                      />
                    </div>
                  </div>
                </div>
                <!-- <p style="max-height:132px; font-family: 'Roboto'">{{ hotspotDescription }}</p> -->
                <div class="unit-desc-text" style="font-family: 'Roboto'" v-html="hotspotDescription" ></div>
              </div>
              </div>
            </div>
          </div>
          <div class="hotspot-row">
            <div
              class="hotspot-img"
              :class="{ active: activeCard === i }"
              v-for="(hotspot, i) in hotspotComponent"
              :key="i"
               
            >
              <img 
                 v-if="hotspot.hotspotDescription != ''"
                :src="getHotSpotImage(hotspot.imageName)"
                alt=""
                class="width-100 active-img"
                @mouseover="activeImage(i, hotspot)"
                @click.stop="activeImage(i, hotspot)"
              />
              <!-- @mouseleave="onCloseDescription" -->
            </div>
          </div>
        </div>
        <div class="image-wraper">
          <div class="right-contentwrapper white-bg">
            <div class="right-image-wrapper right-image-wrapper-box">
              <img class="width-100 right-image-wrapper" :src="storyImage" />
            </div>
            <div class="below-wrapper pb-2">
              <div class="bill-banner-btn">
                <button class="btn round primary unit-opener-btn" @click="gotToUnitStory">
                  {{ $t("read") }}
                </button>
              </div>
              <div class="p-2 text-center overflow-clip mb-4 custom-scrollbar">
                <h3 class="heading-font-wt-bill mt-0 mb-2 pl-1">
                  {{ unitOpenerData.story.storyTitle }}
                </h3>
                <div class="bill-text-description">
                <!-- <p class="text-left pl-2">
                  {{ unitOpenerData.story.storyDescription }}
                </p> -->
                <span class="text-left overflow-clip" v-html="unitOpenerData.story.storyDescription" ></span>
                </div>
              </div>
              <div class="lesson-btn-opener white-bg">
              <div class="bill-banner-btn ">
                <button class="btn round primary unit-opener-btn" @click="goToLessonsDashboard">
                  {{ $t("lessons") }}
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AudioToggle from "@/common/AudioToggle.vue";
export default {
  name: "UnitOpenerOverview",
  components: {
    AudioToggle,
  },
  data() {
    return {
      isHotSpot: false,
      firstLoad: true,
      activeCard: null,
      loadContent: "",
      hotspotTitle: null,
      hotspotDescription: null,
      audioObj: null,
      audioId: null,
      isClickOnHotspot : false
    };
  },
  computed: {
    infoIcon() {
      return require("@/assets/icons/ic_info.svg");
    },
    unitOpenerData() {
      return this.$store.state.unitOpenerPreviewData;
    },
    hotspotComponent() {
      return this.$store.state.unitOpenerPreviewData.hotspots;
    },
    background() {
      return this.getImageUrl(this.unitOpenerData.imageName);
    },
    storyImage() {
      return this.getImageUrl(this.unitOpenerData.story.imageName);
    },
    firstContent() {
      return this.$store.state.unitOpenerPreviewData.description;
    }
  },
  created() {
    // console.log(this.$route.params.unitId, "check unit id")
    this.$store.dispatch("getUnitOpenerDetails", this.$route.params.unitId);
  },
  mounted() {
    this.startTrackingPage({ sectionName: "Unit Overview" });
  //  if(this.activeCard !== null){
  //     this.onCloseDescription()
  //   }
  },
  beforeDestroy() {
    this.$store.commit("loadOpenerPreviewData", null);
  },
  methods: {
    showTeacherPage() {
      if (this.$store.state.isPreviewModeActive) return;
      this.$router.push({
        name: "TeacherPageUnit",
        params: {
          teacherPageId: this.$store.state.currentUnit.skillsId,
        },
        query: {
          title: this.$store.state.currentUnit.name,
        },
      });
    },
    activeImage(i, hotspot) {
      this.isHotSpot = false;
      this.activeCard = i;
      this.hotspotTitle = hotspot.hotspotTitle;
      this.hotspotDescription = hotspot.hotspotDescription;
       if(hotspot.audio !== null){
        this.audioId = hotspot.audio.id;
        this.audioObj = hotspot.audio;
       }
    
      this.isClickOnHotspot = true;
    },
    showUnitDescription() {
      this.isHotSpot = true;
      this.hotspotTitle = this.$store.state.unitOpenerPreviewData.title;
      this.hotspotDescription =
        this.$store.state.unitOpenerPreviewData.description;
      this.activeCard = null;
      if(this.$store.state.unitOpenerPreviewData.audio === null){
        this.audioId = null
        this.audioObj = null
      }
      else{
         this.audioId = this.$store.state.unitOpenerPreviewData.audio.id;
      this.audioObj = this.$store.state.unitOpenerPreviewData.audio;
      }
     
    },
    getHotSpotImage(imageName) {
      return this.getImageUrl(imageName);
    },
    goToLessonsDashboard() {
      this.$router.push({
        name: "Unit",
        params: { unitId: this.unitOpenerData.unitId },
      });
    },
    gotToUnitStory() {
      if (this.unitOpenerData.story.lessonStoryId == "null") {
        this.$store.commit("addSnackBarNotification", {
          message: this.$t("No Lesson-Story Created"),
        });
      } else {
        this.$store.commit('readbtnClicked',{redirectToOpener: true})
        this.$router.push({
          name: "Lesson",
          params: {
            unitId: this.unitOpenerData.unitId,
            lessonId: this.unitOpenerData.story.lessonStoryId,
          },
        });
      }
    },
    onCloseDescription(){
      if(this.activeCard === null){
         this.hotspotTitle = null
         this.hotspotDescription = null
         this.activeCard = null
         this.isHotSpot = false
      }
      else if(this.activeCard != null){
         this.hotspotTitle = null
         this.hotspotDescription = null
         this.activeCard = null
      }
    
    },
  },
};
</script>
<style lang="less" scoped>
.topheader-unit-titlewrapper {
  padding: 8px;
  background-color: #0a1554;
  font-size: 26px;
}
.cursor-pointer {
  cursor: pointer;
}
.overview-title {
  white-space: normal;
  margin: 0px !important;
  font-weight: 600;
  letter-spacing: 0.25px;
}
.unit-opener-overview {
  position: relative;
  margin-top: 100px;
  width: 100%;
  // background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // min-height: 595px;
  height: calc(100vh - 100px);
}
.white-bg {
  background-color: white;
}
.unit-overview-wrapper {
  // height: calc(100vh - 250px);
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  height: calc(100% - 60px);
  // background: #e3dcdc;
}
.text-center {
  text-align: center;
  margin-top: 4px;
  // max-height: 31vh;
  max-height: calc(100% - 74px);
  padding-bottom: 4px;
  // max-height: 167px;
  // height: 218px;
  overflow: auto;
}
.col-md-8 {
  width: 85%;
}
.col-md-4 {
  width: 15%;
}
.width-100 {
  width: 100%;
}
.p-2 {
  padding: 5px;
}
.pb-2 {
  padding-bottom: 15px;
}
.hotspot-row {
  right: 0px;
  position: absolute;
  bottom: 0;
  height: calc(100vh - 100px);
  padding-bottom: 8px;
  min-height: 232px;
  max-height: 232px;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-right: 10px;
  align-items: end;
}
.hotspot-img {
  width: 200px;
  margin: 10px;
  opacity: 0.85;
    // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) { 
    width: 25%;
  }
  img {
    border-radius: 10px;
    border: rgba(245, 245, 245, 0.75) 2px solid;
  }
}
.unit-hotspot-description {
  min-height: 265px;
}
.justify-content-end {
  justify-content: end !important;
}
.col-md-5 {
  width: 50%;
}
.content {
  // top: 60px;
  top: 10px;
  margin-top: 100px;
  min-width: 592px;
  max-height: 204px ;
  // min-height: 224px;
  width: 40%;
  margin: 20px;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 1px 6px 16px 2px #ffffff;
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) { 
    min-width: 80%;
    .heading-font-wt {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
.justify-content-between {
  justify-content: space-between;
}
.hotspot-img.active {
  opacity: 1 !important;
  margin-top: -16px;
  width: 224px;
  margin-inline: 24px;
  box-shadow: 1px 1px 4px 4px #ffffff;
  transform: scale(1.1);
  border-radius: 10px;
  @media (max-width: 991.98px) { 
    width: 32%;
  }
}
.unit-information {
  margin-right: 16px;
  // height: 16px;
  color: #3a4dfd;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 20px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  // img {
  //   height: 20px;
  //   width: 20px;
  // }
  span {
    align-self: center;
    margin-left: 14px;
    text-transform: none;
  }
}
.topheader-unit-title {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 38px;
  font-weight: 600px;
  // text-shadow: -4px 4px 2px rgba(0, 0, 0, 0.6);
}
.bill-banner-btn {
  text-align: center;
  margin-top: 2px;
  // position: absolute;
  // margin-bottom: 10px;
  // transform: translateX(-50%);
  //   left: 50%;
  .btn {
    font-size: 23px;
    font-weight: 500;
  }
}
.heading-font-wt {
  font-family: "Robot", sans-serif;
  font-weight: 600;
  min-width: 528px;
  max-width: 528px;
  text-transform: capitalize;
}
.heading-font-wt-bill {
  font-size: 24px;
  font-family: "Robot", sans-serif;
  font-weight: 600;
}
.right-image-wrapper {
  padding-bottom: 10px;
  max-width: 206px;
  min-height: 198px;
  max-height: 221px;
}
.right-contentwrapper {
  // min-height: 531px;
  position: relative;
  // height: calc(100vh - 160px);
  height: 100%;
  max-width: 206px;
}
.text-left >p {
  white-space: normal;
  text-align: left;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin-top: 0;
  margin-block: 0 !important;
}
.unit-description {
  max-height: 248px;
  min-width: 610px;
  // min-height: 256px;
  width: 610px;
  margin-right: 32px;
  font-family: "Roboto", sans-serif;
  box-shadow: -6px 6px 5px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: -6px 6px 5px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: -6px 6px 5px 0px rgba(0, 0, 0, 0.6);
  border-radius: 0px 0px 20px 20px;
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) { 
    max-height: 80%;
    min-width: 80%;
  }
  >p{
    max-height: 240px;
  }
}
.unit-description-heading {
  max-width: 526px;
  display: flex;
}
.below-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 221px);
}
.heading-text-shadow {
  text-shadow: -6px 2px 2px rgba(0, 0, 0);
}
.unit-description-content{
  height: 100%;
  margin-inline: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}
.unit-opener-btn{
  width: 126px;
  height: 32px;
  font-size: 20px !important;
  letter-spacing: 0.5px;
}
.active-img{
  max-height: 160px;
}
.right-image-wrapper-box{
  min-height: 221px;
}
.lesson-btn-opener{
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  width: 100%;
}
.content-wrapper {
  width: calc(100% - 227px);
  position: relative;
}
.image-wraper{
  display: flex;
  justify-content: end;
  padding-right: 0px;
  // overflow: hidden;
  height: 100%;
  width: 227px;
}
.description-text >span >p{
  max-height: 132px;
}
// .unit-desc-text{
//   height: 85%;
//   overflow-y: auto;
// }
</style>